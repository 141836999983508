import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import { useDispatch, useIntersection } from "stimulus-use";
import cart from "util/cart";
import order from "util/order";
import { getCookie } from "init/cookie";
import unitQuote from "util/quote";
import { _moneyFormat } from "util/formatters";


export default class extends Controller {
  static targets = [
    "card",
    "disableBlock",
    "slider",
    "totalPrice",
    "buttonCard",
    "totalPriceDropdownTrigger",
    "totalDropdown",
    "nightlyRate",
    "priceBlock",
    "errorMessage",
    "availabilityBtn",
    "bookingBtn"
  ];

  connect() {
    useDispatch(this, { debug: true });
    const options = {
      element: this.element,
      rootMargin: "500px",
      swipeThreshold: 5,
      dragThreshold: 5
    };
    const [observe, unobserve] = useIntersection(this, options);
    this.observe = observe;
    this.unobserve = unobserve;
    this.unitId = this.data.get("unit-id");
    this.unitType = this.data.get("unit-type");
    this.unitTypeId = this.data.get("unit-type-id");
    this.setupGlideJS();
    this.setDatesVariables();
    this.setGuestsVariables();
    this.updateBookButton();
    // this.checkItemPresenceInCart();
  }

  setupGlideJS() {
    const slides = this.sliderTarget.querySelector(".glide__slides");
  
    if (slides.children.length === 0) {
      return;
    }
  
    this.glide = new Glide(this.sliderTarget, {
      type: "slider",
      perView: 1,
      gap: 20,
      swipeThreshold: 5,
      dragThreshold: 5
    }).mount();
  
    const bullets = this.sliderTarget.querySelectorAll('.glide__bullet');
    const bulletsContainer = this.sliderTarget.querySelector('.glide__bullets');
  
    bullets[4].classList.add('glide__bullet--right');
  
    this.glide.on("run.after", () => {
      const activeBullet = this.sliderTarget.querySelector('.glide__bullet--active');
      
      if (activeBullet) {
        const containerWidth = bulletsContainer.clientWidth;  
        const bulletOffsetLeft = activeBullet.offsetLeft;     
        const bulletWidth = activeBullet.clientWidth;         
      
        const scrollPosition = bulletOffsetLeft - (containerWidth / 2) + (bulletWidth / 2);
      
        bulletsContainer.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'  
        });
      
        this.updateDots();
      }
    });
  }

  updateDots() {
    const bullets = this.sliderTarget.querySelectorAll('.glide__bullet');
    const activeIndex = Array.from(bullets).findIndex(bullet => bullet.classList.contains('glide__bullet--active'));

    bullets.forEach(bullet => {
      bullet.classList.remove('glide__bullet--left', 'glide__bullet--right');
    });

    if (activeIndex === 1) {
      bullets[activeIndex + 3].classList.add('glide__bullet--right');
      bullets[activeIndex - 1].classList.add('glide__bullet--left');
      return
    }

    if (activeIndex === bullets.length - 2) {
      bullets[bullets.length - 1].classList.add('glide__bullet--right');
      bullets[bullets.length - 5].classList.add('glide__bullet--left');
      return
    }

    if (activeIndex === bullets.length - 1) {
      bullets[bullets.length - 5].classList.add('glide__bullet--left');
      return
    }

    if (activeIndex === 0) {
      bullets[activeIndex + 4].classList.add('glide__bullet--right');
      return
    }
    if (activeIndex - 2 >= 0 && activeIndex !== bullets.length - 1) {
      bullets[activeIndex - 2].classList.add('glide__bullet--left');
    }

    if (activeIndex + 2 < bullets.length ) {
      bullets[activeIndex + 2].classList.add('glide__bullet--right');
    }
  }


  disconnect() {
    if (this.glide) this.glide.destroy();
  }

  setDatesVariables() {
    // get dates from url parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.checkin = urlParams.get("checkin");
    this.checkout = urlParams.get("checkout");

    if (this.checkin && this.checkout) return;

    // get dates from cookies if url parameters doesn't contain dates
    const cookies = JSON.parse(getCookie("filter_by_dates") || "{}");
    this.checkin = cookies.checkin;
    this.checkout = cookies.checkout;
  }

  setGuestsVariables() {
    // get guests from url parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.adults = urlParams.get("adults") || 0;
    this.children = urlParams.get("children") || 0;

    if (this.adults || this.children) return;

    // get guests from cookies if url parameters doesn't contain them
    const cookies = JSON.parse(getCookie("filter_by_guests") || "{}");
    this.adults = cookies.adults || 0;
    this.children = cookies.children || 0;
  }

  updateBookButton() {
    if (!this.checkin || !this.checkout) {
      this.bookingBtnTarget.innerText = 'Choose Dates';
      this.buttonCardTarget.style = "display: none";

    } else if (!(parseInt(this.adults, 10))) {
      this.bookingBtnTarget.innerText = 'Enter Guests';
      this.buttonCardTarget.style = "display: none";

    } else {
      this.bookingBtnTarget.removeAttribute("disabled");
      this.buttonCardTarget.style = "display: flex";
    }
  }

  bookingItemParams() {
    return {
      unit_id: this.unitId,
      type: this.unitType,
      arrival_date: this.checkin,
      departure_date: this.checkout,
      unit_type_id: this.unitTypeId,
      adults: this.adults,
      children: this.children,
      total: this.total
    };
  }

  appear() {
    this.sendQuoteRequest();
    this.unobserve();
  }

  toogleTotalPriceDropdown(event) {
    event.stopPropagation();
    this.totalPriceDropdownTriggerTarget.classList.toggle("open");
  }

  addItemToCartForBooking(e) {
    e.preventDefault();
    const buttonForm = e.currentTarget;
    if (this.checkin && this.checkout && parseInt(this.adults, 10)) {
      order.create([this.bookingItemParams()]);
      buttonForm.submit();
    }
  }

  addItemToCart() {
    if (
      this.buttonCardTarget.textContent.trim().toLowerCase() === ("Add to Cart").toLocaleLowerCase()
    ) {
      const isItemPresentInCart = cart.item(this.unitType, this.unitId);
      if (!isItemPresentInCart && this.checkin && this.checkout && parseInt(this.adults, 10)) {
        cart.add(this.bookingItemParams());
        this.setSelectedCartButtonState();
        window.dispatchEvent(new CustomEvent(`cartUpdated-${this.unitId}`));
        window.dispatchEvent(new CustomEvent(`cartUpdated`));
      }
    } else if (
      this.buttonCardTarget.textContent.trim().toLowerCase() === "Update".toLocaleLowerCase()
      ) {
        const isItemPresentInCart = cart.item(this.unitType, this.unitId);
        if (isItemPresentInCart && this.checkin && this.checkout && parseInt(this.adults, 10)) {
          this.handleUpdateInCartByCard();
        }
      }
  }

  checkItemPresenceInCart() {
    const isItemPresentInCart = cart.item(this.unitType, this.unitId);

    if (isItemPresentInCart) {
      this.setSelectedCartButtonState();
    } else {
      this.setUnselectedCartButtonState();
    }
  }

  setUpdateCartButtonState() {
    this.buttonCardTarget.classList.add("selected");
    this.buttonCardTarget.textContent = "Update";
  }

  setSelectedCartButtonState() {
    this.buttonCardTarget.classList.add("selected");
    this.buttonCardTarget.textContent = "In Cart";
  }

  setUnselectedCartButtonState() {
    this.buttonCardTarget.classList.remove("selected");
    this.buttonCardTarget.textContent = "Add to Cart";
  }

  // handleUpdateInCartMap(e) {
  //   // cart.update(e.detail.unitId, e.detail.bookingItemParams);
  //   this.updateCartButtonState();
  //   window.dispatchEvent(new CustomEvent(`cartUpdated-${e.detail.unitId}`));
  //   window.dispatchEvent(new CustomEvent(`cartUpdated`));
  // }

  handleUpdateInCartByCard() {
    const { unitId } = this;
    cart.update(this.unitType, unitId, this.bookingItemParams());
    this.updateCartButtonState();
    window.dispatchEvent(new CustomEvent(`cartUpdated-${unitId}`));
    window.dispatchEvent(new CustomEvent(`cartUpdated`));
  }

  handleUpdateInCart(e) {
    const { unitId } = this;
    if (e.detail.bookingItemParams) {
      cart.update(this.unitType, unitId, e.detail.bookingItemParams);
      this.updateCartButtonState();
      window.dispatchEvent(new CustomEvent(`cartUpdated-${unitId}`));
      window.dispatchEvent(new CustomEvent(`cartUpdated`));
    }
  }

  learnMoreClick(event) {
    event.stopPropagation();
  }

  handleClickOpenModal(event) {
    event.stopPropagation();
  }

  handleSelectedUnit(e) {
    if (e.detail.unitId !== this.unitId) return;
    if (e.detail.total) {
      this.total = e.detail.total;
    }
    if (this.checkin && this.checkout) {
      this.addItemToCart();
    }
  }

  async sendQuoteRequest() {
    if (!this.checkin || !this.checkout) return;

    const data = {
      unit_id: this.unitId,
      type: this.unitType,
      arrival_date: this.checkin,
      departure_date: this.checkout
    };
    this.priceBlockTarget.classList.add("loading");

    const result = await unitQuote(data);
    this.handleQuoteResponse(result);
  }

  handleQuoteResponse(response) {
    this.priceBlockTarget.classList.remove("loading");
    if (response.status && response.status === "error") {
      this.disableBlockTarget.classList.add("active");
      this.priceBlockTarget.style.display = "none";
      this.errorMessageTarget.style.display = "block";
      this.sliderTarget.style.opacity = "0.5";
      this.sliderTarget.style.pointerEvents = "none";
      this.cardTarget.style.cursor = "default";
      this.availabilityBtnTarget.style.display = 'none';
    } else {
      this.handleQuoteSuccessfulResponse(response);
      this.updateCartButtonState();
      this.availabilityBtnTarget.style.display = 'flex';
    }
  }

  updateCartButtonState() {
    const bookigItemFromCart = cart.item(this.unitType, this.unitId);
    if (
      bookigItemFromCart &&
      JSON.stringify(bookigItemFromCart) ===
        JSON.stringify(this.bookingItemParams())
    ) {
      this.setSelectedCartButtonState();
      this.dispatch(`updateButtonState-${this.unitId}`, {
        buttonState: "inCart"
      });
    } else if (bookigItemFromCart) {
      this.setUpdateCartButtonState();
      this.dispatch(`updateButtonState-${this.unitId}`, {
        buttonState: "updateCart"
      });
      this.dispatch(`bookigItemFromCart-${this.unitId}`, {
        bookingItemParams: this.bookingItemParams()
      });
    } else {
      this.setUnselectedCartButtonState();
      this.dispatch(`updateButtonState-${this.unitId}`, {
        buttonState: "addToCart"
      });
    }
  }

  handleQuoteSuccessfulResponse(response) {
    const {
      guest_fees: feeItems,
      nightly_rate: nightlyRate,
      total_taxes: taxes,
      total
    } = response;
    const totalDropdownItems = [...feeItems];

    const totalWithoutTaxes = total - taxes;
    this.updateNightlyRate(nightlyRate);
    this.updateTotalDropdown(totalDropdownItems);
    this.updateTotal(totalWithoutTaxes);
  }

  updateNightlyRate(nightlyRate) {
    this.nightlyRateTarget.textContent = `${_moneyFormat(nightlyRate)} / night`;
  }

  updateTotalDropdown(dropdownItems) {
    this.totalDropdownTarget.innerHTML = "";
    dropdownItems.forEach(item => {
      const itemPrice = parseFloat(item.value);
      const itemHtml = `
        <div class="dropdown-row">
          <div class="dropdown-row-text">${item.name}</div>
          <div class="dropdown-row-text">${_moneyFormat(itemPrice)}</div>
        </div>
      `;
      this.totalDropdownTarget.insertAdjacentHTML("beforeend", itemHtml);
    });
  }

  updateTotal(totalPrice) {
    this.total = totalPrice;
    this.dispatch(`saveTotal-${this.unitId}`, {
      unitId: this.unitId,
      total: this.total
    });
    this.totalPriceTarget.textContent = `${_moneyFormat(totalPrice)}`;
  }
}
